import './bootstrap';
import '../css/app.css';

import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

import * as Sentry from "@sentry/vue";

import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fas)

import VueGoogleMaps from '@fawmi/vue-google-maps'
import { setupI18n } from '@/i18n';
import VueViewer from 'v-viewer'
import VueLazyload from 'vue-lazyload'
import VueMeta from 'vue-meta';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3Storage from "vue3-storage";
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

const appName = import.meta.env.VITE_APP_NAME || 'Laravel';

createInertiaApp({
  title: (title) => `${title} - ${appName}`,
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) });

    const i18n = setupI18n({
        legacy: true,
        locale: props.initialPage.props.locale,
        fallbackLocale: 'en',
        messages: {}
      })

    Sentry.init({
      app,
      dsn: "https://0754e0e98cdb4aa49b96d7d0d3683286@o391730.ingest.sentry.io/5499986",
      ignoreErrors: ['ResizeObserver loop limit exceeded'],
      environment: import.meta.env.VITE_SENTRY_ENV,
      beforeSend(event) {
        var errorType = event.exception.values[0].type;
        if (errorType === 'ChunkLoadError')
        {
          window.location.reload();
          return null;
        }
        return event;
      },
      integrations: [
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            'localhost',
            'balloco.net',
            /^\//,
          ],
        }),
        new Sentry.Replay(),
      ],
      tracesSampleRate: 1.0,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
    });

    app.use(plugin)
       .use(ZiggyVue)
       .use(VueGoogleMaps, {
        load: {
            key: import.meta.env.VITE_GOOGLEMAPS_API_TOKEN,
          },
        })
       .use(VueViewer)
       .use(VueLazyload, {
          lazyComponent: true
        })
       .use(Vue3Storage)
       //.use(VueMeta)
       .use(VueSweetalert2)
       .use(i18n)
       .component('font-awesome-icon', FontAwesomeIcon)
       .mount(el);

      app.config.globalProperties.$helpers = {
        shuffle(array) {
          let currentIndex = array.length,  randomIndex;

          // While there remain elements to shuffle...
          while (currentIndex != 0) {

            // Pick a remaining element...
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;

            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
              array[randomIndex], array[currentIndex]];
          }
          return array;
        },
      }

      dayjs.extend(utc);
      dayjs.extend(timezone);
      app.config.globalProperties.$dayjs = dayjs;

      app.config.globalProperties.$filters = {
        formatDate(value) {
          let date = new Date(value);
          return date.toLocaleDateString();
        },
        formatDateTime(value) {
          let date = new Date(value);
          return date.toLocaleDateString() + ' ' + date.toLocaleTimeString();
        },
        formatCurrency(value) {
          return parseFloat(value).toLocaleString(undefined, { style: 'currency', currency: 'EUR' });
        },
        checkUnknown(value, component) {
          if ((!value) || (value == 'null')) {
            return component.$i18n.t('unknown');
          }
          return value;
        },
        checkNull(value) {
          if ((!value) || (value == 'null')) {
            return '-';
          }
          return value;
        },
        checkFoundSelf(value, component) {
          if (value === 'SELF') {
            return component.$i18n.t('you');
          }
          return value;
        },
        hideNull(value) {
          if ((!value) || (value == 'null')) {
            return "–";
          }
          return value;
        }
      }

    return app;
  },
  progress: {
    color: '#4B5563',
  },
});
